import React from 'react';
import { connect, router } from 'dva';
import NavBar from 'components/NavBar';
import { SideBar } from 'components/SideBar';
import pathToRegexp from 'path-to-regexp';
import $$ from 'cmn-utils';
import isEqual from 'react-fast-compare';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import 'assets/styles/transition.less';
import './styles/basic.less';
const { Switch } = router;
let $this;

@connect(({ global }) => ({ global }))
export default class BasicLayout extends React.PureComponent {

	constructor(props) {
		super(props);
		const user = $$.getStore('user', []);
		this.state = {
			user,
			currentMenu: {},
		};
		props.dispatch({
			type: 'global/getUserInfo'
		});
		$this = this;
	}

	async componentDidMount() {
		// sso 过来  携带token
		let arr = window.location.href.split('&token=')
		if(arr.length > 1){
			localStorage.setItem('token',arr[1])
			window.location.href = arr[0]
			return
		}
		const token = localStorage.getItem('token')
		if (!token) {
			window.location.href = window.location.origin + '/sign/login'

			// const res = await $$.post('/platform/api/v1/sso/get-sso-auth-url', {
      //   clientLoginUrl: window.location.href
      // }, {
      //   withHeaders: {
      //     'content-type': 'application/x-www-form-urlencoded',
      //   }
      // })
			// if(res.success){ 
			// 	window.location.href = res.data
			// }else{
			// 	window.location.href = window.location.origin + '/sign/login'
			// }
			// return
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!isEqual(this.props.location.pathname, prevProps.location.pathname) ||
			!isEqual(this.props.global.flatMenu, prevProps.global.flatMenu)
		) {
			this.setState({
				currentMenu: this.getCurrentMenu(this.props) || {}
			});
		}
	}


	getCurrentMenu(props) {
		const { location: { pathname }, global } = props || this.props;
		const menu = this.getMeunMatchKeys(global.flatMenu, pathname)[0];
		return menu;
	}

	getMeunMatchKeys = (flatMenu, path) => {
		return flatMenu.filter(item => {
			if (item.path) {
				return pathToRegexp(item.path).test(path);
			}
		});
	};

	render() {
		const {
			currentMenu,
		} = this.state;
		const {
			routerData,
			location,
			global
		} = this.props;
		const {
			menu,
			flatMenu,
			userInfo,
			factoryInfo,
			orgList,
		} = global;

		const { childRoutes } = routerData;

		const sys =
			<>
				<NavBar user={userInfo} factory={factoryInfo} orgList={orgList} />
				<div className="sys-center">
					<SideBar location={location} flatMenu={flatMenu} currentMenu={currentMenu} menu={menu} />
					<div className="sys-container">
						<SwitchTransition >
							<CSSTransition key={location.pathname} classNames="fade" timeout={500}>
								<Switch className="router-page full-layout sys-container" location={location}>{childRoutes}</Switch>
							</CSSTransition>
						</SwitchTransition>
					</div>
				</div>
			</>
		const result = localStorage.getItem('token') ? sys : ''
		return result
	}
}