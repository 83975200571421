import {createRoute} from '@/utils/core';
import Routers from '@/layouts/Routers';
import NotFound from '@/routes/Pages/404';
import Index from './routers/Index';
import Classify from './routers/Classify';

const routesConfig = (app) => ({
  path: '/algorithm',
  title: '算法管理',
  component: Routers,
  exact: false,
  indexRoute: '/algorithm/index',
  childRoutes: [
    Index(app),
    Classify(app),
    NotFound(),
  ]
});

export default (app) => createRoute(app, routesConfig);
