import {dynamicWrapper, createRoute} from '@/utils/core';
import Routers from '@/layouts/Routers';
import NotFound from '@/routes/Pages/404';
import Template from './routers/Template';
import Report from './routers/Report';

const routesConfig = (app) => ({
  path: '/privateFill',
  title: '私有填报单管理',
  component: Routers,
  exact: false,
  indexRoute: '/privateFill/template',
  childRoutes: [
    Template(app),
    Report(app),
    NotFound(),
  ]
});

export default (app) => createRoute(app, routesConfig);
