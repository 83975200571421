import React, { PureComponent, useRef } from 'react';
import { Popover, Divider } from 'antd';
import './style/index.less';
import logoImg from 'assets/images/logo.png';
import avatarImg from 'assets/images/avatar.png';
import moreImg from 'assets/images/head/more.png';
import $$ from 'cmn-utils';
import Password from './Password'

const pwdRef = React.createRef()

class NavBar extends PureComponent {

  //全屏切换
  toggleFullScreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  //切换当前工厂
  checkFactory = async (record) => {
    if (record.current) return
    const res = await $$.post('/platform/api/v1/login/switch', {
      orgId: record.orgId
    })
    if (res?.success && res?.data?.accessToken) {
      localStorage.setItem('token', res.data.accessToken);
      window.location.href = '/home'
    }
  };

  //退出登录  
  async logout() {
    const res = await $$.get('/platform/api/v1/sso/signout') 
    // const res = await $$.post('/platform/api/v1/logout') 
    if (res.success) {
      $$.removeStore('user');
      localStorage.removeItem('token');
      window.location.href = '/sign/login'
    }
  }

  // onChangePassword = () => {
  //   pwdRef.current.open(true)
  // }

  render() {
    const { factory, orgList } = this.props;
    let username = ""
    if ($$.getStore('userInfo')) {
      username = $$.getStore('userInfo').username
    }
    const orgName = localStorage.getItem('orgName')
    const onChangePassword = () => {
      pwdRef.current.open()
    }

    let avatar = $$.getStore('userInfo')?.avatar || ''

    //用户更多操作 
    const UserDropDown = () => (
      <div>
        <div className="dropdowm-item no-click">
          {`账号：${username}`}
        </div>
        <Divider style={{margin: "6px 0"}}/>
        <div onClick={() => this.toggleFullScreen()} className="dropdowm-item">
          <img src={require('@/assets/svg/head/full.svg')} alt="editPassword" />
          <span>全屏切换</span>
        </div>
        <div onClick={() => onChangePassword()} className="dropdowm-item">
          <img src={require('@/assets/svg/head/editPwd.svg')} alt="editPassword" />
          <span>修改密码</span>
        </div>
        <div onClick={() => this.logout()} className="dropdowm-item">
          <img src={require('@/assets/svg/head/logout.svg')} alt="logout" />
          <span>退出登录</span>
        </div>
      </div>
    );

    let name = ''
    let imgFun = ''
    let userType = JSON.parse(localStorage.getItem('userInfo'))?.managerType
    //防止图标/名字替换带来的闪烁   type为1的管理员用户有可能没有factory信息
    if (userType == 1 || factory?.factoryId) {
      name = factory?.alias || '数字孪生建模工具'
      let logo = factory?.logo || logoImg
      imgFun = <img src={logo} alt="logo" className='logo-img' />
    }


    return (
      <header className='sys-head'>
        {imgFun}
        <div className='logo-name'>{name}</div>
        <div className="dropdown">
          <Popover placement="bottomRight" content={<UserDropDown />} trigger="hover">
            <img src={avatar || avatarImg} className="avatar" />
          </Popover>
        </div>

        {orgList.length ?
          <div className='dropdown'>
            <Popover trigger="hover" placement="bottomRight"
              content={
                <div>
                  {orgList.map(item =>
                    <div className={`dropdowm-item dropdowm-factory ${item.current ? 'dropdowm-item--select' : ''}`}
                      key={item.orgId} onClick={() => this.checkFactory(item)} >
                        <div className={`${item.orgName.length > 7 ? 'run' : ''}`}>{item.orgName}</div>
                      </div>
                  )}
                </div>
              }
            >
              <div className='factory-info'>
                <span>{orgName}</span>
                <img src={moreImg} />
              </div>
            </Popover>
          </div>
          : <div className='dropdown factory-info'>{orgName}</div>
        }
        <Password refs={pwdRef} />
      </header>
    );
  }
}
export default NavBar;
