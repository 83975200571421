import React, { PureComponent } from 'react';
import { Menu, Tooltip } from 'antd';
import { router } from 'dva';
import pathToRegexp from 'path-to-regexp';
import './style/index.less';

const { Link } = router;
const SubMenu = Menu.SubMenu;


export const getMeunMatchKeys = (flatMenu, path) => {
  return flatMenu.filter(item => {
    if (item.path) {
      return pathToRegexp(item.path).test(path);
    }
  });
};

class LeftSideBar extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      openKeys: props.currentMenu ? props.currentMenu.parentPath : []
    };
    this.teamRef = React.createRef();
  }

  //svg图标名称
  getSvgName = (name) =>{
    const arr = ['algorithm','big-screen','collect','dataAnalysis','dataQuality','digitalScreen','factory','flow',
    'forms','home','industryModel','model','property','sheet','system','task','tpl','warning']
    if(arr.indexOf(name) == -1){
      return 'defalut'
    }
    return name
  }

  getIcon = (itemPath) => {
    let name = this.getSvgName(itemPath?.slice(1))
    return <img className='icon' src={require(`@/assets/svg/side/${name}.svg`)} />
  };

  //无子集菜单栏
  getMenuItemPath = item => {
    const itemPath = this.conversionPath(item.path);
    const { target, name } = item;

    //跳转外部链接
    if (/^https?:\/\//.test(itemPath)) {
      return (
        <a href={itemPath} target={target}><span>{name}</span></a>
      );
    }
    let arrs = itemPath.split('/');
    let img = ''
    if (arrs.length == 2) {
      let imgName = this.getSvgName(itemPath.slice(1))
      img = <img className={`icon ${itemPath === this.props.location.pathname ? 'icon--select' : ''}`} src={require(`@/assets/svg/side/${imgName}.svg`)} />
    }

    return (
      <Tooltip title={name}>
        <Link to={itemPath} target={target} className="meun-hover" replace={itemPath === this.props.location.pathname}>
          {img}
          <span className={`${itemPath === this.props.location.pathname ? 'path-select' : ''}`}>{name}</span>
        </Link>
      </Tooltip>
    );
  };

  // 转化路径
  conversionPath = path => {
    if (path && path.indexOf('http') === 0) {
      return path;
    } else {
      return `/${path || ''}`.replace(/\/+/g, '/').replace(/\/:\w+\??/, '');
    }
  };


  //多级或一级菜单
  getSubMenuOrItem = item => {
    if (item.children && item.children.some(child => child.name)) {
      const childrenItems = this.getNavMenuItems(item.children);
      if (childrenItems?.length) {
        return (
          <SubMenu className="meun-hover" 
            title={ 
              <Tooltip title={item.name}>
                <span>
                  {this.getIcon(item.path)}<span>{item.name}</span>
                </span>
              </Tooltip>
            } 
            key={item.path} 
          >
            {childrenItems}
          </SubMenu>
        );
      }
      return null;
    } else {
      return (
        <Menu.Item key={item.path}>{this.getMenuItemPath(item)}</Menu.Item>
      );
    }
  };

 

  //获得菜单子节点
  getNavMenuItems = menusData => {
    if (!menusData) {
      return [];
    }
    return menusData
      .filter(item => item.name && !item.hideInMenu)
      .map(item => {
        const ItemDom = this.getSubMenuOrItem(item);
        return ItemDom;
      })
      .filter(item => item);
  };



  // Get the currently selected menu
  getSelectedMenuKeys = () => {
    const pathname = this.props.location.pathname;
    const selectMenu = getMeunMatchKeys(this.props.flatMenu, pathname)[0];
    return selectMenu ? [selectMenu.path] : [];
  };

  isMainMenu = key => {
    return this.props.menu.some(
      item => key && (item.key === key || item.path === key)
    );
  };

  handleOpenChange = openKeys => {
    const lastOpenKey = openKeys[openKeys.length - 1];
    const moreThanOne =
      openKeys.filter(openKey => this.isMainMenu(openKey)).length > 1;
    this.setState({
      openKeys: moreThanOne ? [lastOpenKey] : [...openKeys]
    });
  };

  render() {
    const { menu } = this.props;

    const { openKeys } = this.state;
    let selectedKeys = this.getSelectedMenuKeys();
    const menuProps = { openKeys, selectedKeys };

    const siderBar = (
      <div className="sys-sidebar">
        <div className="sidebar">
          <Menu onClick={this.handleClick} onOpenChange={this.handleOpenChange} mode="inline"  {...menuProps} >
            {this.getNavMenuItems(menu)}
          </Menu>
        </div>
      </div>
    );
    return siderBar
  }
}

export default LeftSideBar;
