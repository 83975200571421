import { routerRedux } from 'dva';
import $$ from 'cmn-utils';
import modelEnhance from '@/utils/modelEnhance';
import NavBar from '@/components/NavBar/NavBar';
import moment from 'moment'
// import { delete } from 'fetch-mock';

export default modelEnhance({
	namespace: 'global',
	state: {
		menu: [],
		flatMenu: [],
		userInfo: {},
		factoryInfo: {},
		domain: {
			canvas: process.env.REACT_APP_DOMAIN_CANVAS,
			wps: process.env.REACT_APP_DOMAIN_WPS
		},
		showTeam: false, //是否显示 team
		teamList: [], // 我的团队列表
		currentTeamCode: 0, // 当前团队code

		showOrg: false, //是否显示 org
		orgList: [], // 工厂列表
		currentOrgId: 0 // 当前工厂Id
	},
	effects: {
		* reset({ payload }, { call, put }) {
			yield put({
				type: 'resetSuccess',
				payload: {
					showOrg: false,
					orgList: [],
					currentOrgId: 0,
				}
			});
		},
		* getMenu({ payload }, { call, put }) {
			const { status, data } = yield call(getMenu, payload);
			if (status) {
				const loopMenu = (menu, pitem = {}) => {
					menu.forEach(item => {
						if (pitem.path) {
							item.parentPath = pitem.parentPath ? pitem.parentPath.concat(pitem.path) : [pitem.path];
						}
						if (item.children && item.children.length) {
							loopMenu(item.children, item);
						}
					});
				}
				loopMenu(data);
				yield put({
					type: 'getMenuSuccess',
					payload: data,
				});
			}
		},
		* getDict({ payload }, { call, put }) {
			const { status, data } = yield call(getDict, payload);
			let domain = {}
			data.map(item => {
				domain[item.dictValue] = item.dictLabel
			})

			if (status) {
				yield put({
					type: 'getDictSuccess',
					payload: {
						domain
					}
				});
			}
		},
		* getUserInfo({ payload }, { call, put }) {
			// 没token信息的时候不请求用户信息
			if (!localStorage.getItem('token')) {
				return
			}
			const { success, data } = yield call(getUserInfo, payload);
		
			if (success && data) {
				if (data.userInfo ) {
					if (data?.orgs.length) {
						yield put({
							type: 'showOrgSuccess',
							payload: {
								showOrg: true,
								orgList: data.orgs
							}
						});
						//current 当前选中的工厂
						const orgCurrent = data.orgs.find(item => item.current)
						if (orgCurrent) {
							localStorage.setItem('orgName',orgCurrent.orgName)
							localStorage.setItem('orgId',orgCurrent.orgId)
							const { orgId: factoryId } = orgCurrent
							$$.setStore('factoryId', factoryId)
							if (factoryId != 1) {
								yield put({
									type: 'getFactoryInfo',
									payload: {
										factoryId
									}
								});
							}
						}
					}
					$$.setStore('userInfo', data.userInfo)
          // window.dispatchEvent(new CustomEvent('compel_change_pwd', { detail: data.userInfo?.pwdUpdateTime }))

					// if (data.userInfo?.pwdUpdateTime && (moment().diff(moment(data.userInfo.pwdUpdateTime),'days') > 30)) {
					//   const navBarComponent = new NavBar()
					//   navBarComponent.onChangePassword()
					// }
				}

				yield put({
					type: 'getUserInfoSuccess',
					payload: data,
				});
			}
		},
		* getTeamList({
			payload
		}, {
			call,
			put
		}) {
			const {
				success,
				data
			} = yield call(getTeamList, {});
			if (success && data) {
				let currentTeamCode = 0;
				if (data.length) currentTeamCode = data[0].teamCode;
				yield put({
					type: 'getTeamListSuccess',
					payload: {
						currentTeamCode,
						teamList: data
					},
				});
			}
		},
		* changeCurrentTeam({
			payload
		}, {
			call,
			put
		}) {
			let currentTeamCode = payload.teamCode;
			yield put({
				type: 'getTeamListSuccess',
				payload: {
					currentTeamCode,
				},
			});
		},
		* getFactoryInfo({payload}, {call,put}) {
			const {
				success,
				data
			} = yield call(getFactoryInfo, payload);
			if (success && data) {
				const {
					factoryId,
					logo,
					alias
				} = data
				yield put({
					type: 'getFactoryInfoSuccess',
					payload: {
						factoryInfo: {
							factoryId,
							logo,
							alias
						}
					},
				});
			}
		},
		// *getFactoryList({ payload }, { call, put }) {
		//   const { success, data } = yield call(getFactoryList);
		//   if(success && data){
		//     yield put({
		//       type: 'getFactoryListSuccess',
		//       payload: {
		//         factoryList: data
		//       }
		//     });
		//   }
		// },
	},

	reducers: {
		resetSuccess(state, {
			payload
		}) {
			return {
				...state,
				...payload
			};
		},
		getMenuSuccess(state, {
			payload
		}) {
			return {
				...state,
				menu: payload,
				flatMenu: getFlatMenu(payload),
			};
		},
		getDictSuccess(state, {
			payload
		}) {
			return {
				...state,
				...payload
			};
		},
		getUserInfoSuccess(state, {
			payload
		}) {
			let {
				menus,
				permissions,
				orgs
			} = payload;
			menusClearUp(menus)
			menus.forEach(item => {
				if (!item.icon) item.icon = 'DesktopOutlined';
				if (item.children) {
					item.children.forEach(v => {
						if (!v.icon) v.icon = null;
						// if(v.path == '/project/my'){
						//   v.path = '/project/my/index';
						// }
					});
				}
			});
			const menuHome = menus.find(item => item.path == '/home')
			if (menuHome) {
				menuHome.icon = 'HomeOutlined'
			} else {
				menus.unshift({
					name: '首页',
					icon: 'HomeOutlined',
					path: '/home',
				});
			}
			menus = [...menus];
			return {
				...state,
				menu: menus,
				permissions,
				orgs,
				flatMenu: getFlatMenu(menus),
				userInfo: payload.userInfo,
			};
		},
		showOrgSuccess(state, {
			payload
		}) {
			return {
				...state,
				...payload,
			};
		},
		showTeamSuccess(state, {
			payload
		}) {
			return {
				...state,
				...payload,
			};
		},
		getTeamListSuccess(state, {
			payload
		}) {
			return {
				...state,
				...payload,
			};
		},
		getFactoryInfoSuccess(state, {
			payload
		}) {
			return {
				...state,
				...payload,
			};
		},
	},
});

export function getFlatMenu(menus) {
	let menu = [];
	menus.forEach(item => {
		if (item.children) {
			menu = menu.concat(getFlatMenu(item.children));
		}
		menu.push(item);
	});
	return menu;
}

export function menusClearUp(arr) {
	for (var i = 0; i < arr.length; i++) {
		// console.log(arr[i].children);
		if (arr[i].children != undefined) {
			// console.log(arr[i].children);
			menusClearUp(arr[i].children)
		}
		if (arr[i].type == 2) {
			arr.splice(i, 1);
			i--;
		}
	}
}

export async function getMenu(payload) {
	return $$.post('/user/menu', payload);
}

//获取用户信息
export async function getUserInfo(payload) {
	//如果没有token信息  跳转登录页面 
	if (!localStorage.getItem('token')) {
		window.location.href = window.location.origin + '/sign/login'
		return
	}
	return $$.get('/platform/api/v1/users/current-user-info', {
		systemCode: 'system'
	});
}
export async function getFactoryInfo(payload) {
	const {
		factoryId
	} = payload
	return $$.get(`/datacenter/api/v1/factorys/${factoryId}`);
}
// export async function getFactoryList() {
//   return $$.get(`/datacenter/api/v1/factorys/list`);
// }
export async function getTeamList() {
	return $$.get('/synergy/api/v1/teams/my');
}
export async function getDict() {
	return $$.get('/platform/api/v1/dict-data/list', {
		dictType: 'domain'
	});
}