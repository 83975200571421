import {dynamicWrapper, createRoute} from '@/utils/core';
import Routers from '@/layouts/Routers';
import NotFound from '@/routes/Pages/404';
import Manage from './routers/Manage';
import Labels from './routers/Labels';

const routesConfig = (app) => ({
  path: '/industryModel',
  title: '工业模型',
  component: Routers,
  exact: false,
  indexRoute: '/industryModel/manage',
  childRoutes: [
    Manage(app),
    Labels(app),
    NotFound(),
  ]
});

export default (app) => createRoute(app, routesConfig);
