import React from 'react';
import { connect, router } from 'dva';
const { Switch } = router;

@connect(({ global }) => ({ global }))
export default class Routers extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const { routerData, location } = this.props;
    const { childRoutes } = routerData;
    return (
      <Switch location={location}>{childRoutes}</Switch>
    );
  }
}
