import {createRoute} from '@/utils/core';
import Routers from '@/layouts/Routers';
import NotFound from '@/routes/Pages/404';
import Module from './routers/Module';
import Menu from './routers/Menu';
import Resource from './routers/Resource';
import Department from './routers/Department';
import Role from './routers/Role';
import SSO from './routers/SSO';
import Fun from './routers/Fun'


const routesConfig = (app) => ({
  path: '/system',
  title: '系统管理',
  component: Routers,
  exact: false,
  indexRoute: '/system/menu',
  childRoutes: [
    Module(app),
    Menu(app),
    Resource(app),
    Department(app),
    Fun(app),
    Role(app),
    SSO(app),
    NotFound(),
  ]
});

export default (app) => createRoute(app, routesConfig);
