import React, { useState } from 'react'
import { Modal, Form, Input, message, List, Typography, Alert, Button } from 'antd'
import $$ from 'cmn-utils';


export default function ({ refs }) {
  refs.current = {
    open: (noClose) => {
      setVisible(true)
      if (noClose) {
        setCompelChange(noClose)
      }
    },
    close: () => {
      if (loading) return
      setLoading(false)
      setVisible(false)
      formRef.current.setFieldsValue({ oldPassword: '' });
      formRef.current.setFieldsValue({ newPassword: '' });
      formRef.current.setFieldsValue({ confirmPassword: '' });
    }
  }

  const [visible, setVisible] = useState(false)
  const [errorVisible, setErrorVisible] = useState(false)
  const [errorData, setErrorData] = useState([])
  const [loading, setLoading] = useState(false)
  const [compelChange, setCompelChange] = useState(false)
  const formRef = React.createRef()

  const handleSubmit = async () => {
    const { oldPassword, newPassword, confirmPassword } = formRef.current.getFieldValue()
    if (!oldPassword || !newPassword || !confirmPassword) {
      message.info('密码不能为空！')
      return
    }
    if (newPassword != confirmPassword) {
      message.info('新密码与确认密码不一致！')
      return
    }
    setLoading(true)
    const res = await $$.send('/platform/api/v1/users/change-password', {
      method: 'PUT',
      data: {
        ...formRef.current.getFieldValue()
      }
    })
    if (res.success) {
      message.success("密码修改成功！三秒后自动跳转登录~")
      setTimeout(() => {
        $$.removeStore('user');
        localStorage.removeItem('token');
        window.location.href = '/sign/login'
      }, 3000);
    } else if (!res.success && res.code == "valid_param" && res.data.length != 0) {
      setErrorVisible(true)
      setErrorData(res.data)
    }
    setLoading(false)
  }

  const handler = {
    errorClose: () => {
      setErrorVisible(false)
    }
  }

  return (
    <>
      <Modal title='修改密码' visible={visible} confirmLoading={loading} onOk={handleSubmit} onCancel={refs.current.close} okText={'确定'} cancelText={'取消'} width={500} maskClosable={false} closable={!compelChange}
        footer={
          compelChange ?
            [
              <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>确定</Button>
            ]
            : [
              <Button key="back" onClick={refs.current.close}>取消</Button>,
              <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>确定</Button>
            ]
        }
      >
        <Form ref={formRef} name="basic" labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
          <Form.Item label="旧密码" name="oldPassword" rules={[{ required: true, message: '旧密码不能为空!' }]}>
            <Input placeholder="旧密码" type="password" />
          </Form.Item>
          <Form.Item label="新密码" name="newPassword" rules={[{ required: true, message: '新密码不能为空!' }]} >
            <Input placeholder="新密码" type="password" />
          </Form.Item>
          <Form.Item label="确认密码" name="confirmPassword" rules={[{ required: true, message: '确认密码不能为空!' }]} >
            <Input placeholder="确认密码" type="password" />
          </Form.Item>
        </Form>
      </Modal>
      <Modal title='错误信息' visible={errorVisible} onCancel={handler.errorClose} width={500} maskClosable={false} footer={false}>
        {/* <List
          header={null}
          footer={null}
          dataSource={errorData}
          renderItem={(item) => (
            <List.Item>
              {item.fieldName == "newPassword" ? "新密码" : item.fieldName == "confirmPassword" ? "确认密码" : item.fieldName == "oldPassword" ? "旧密码" : ""}： {item.message}
            </List.Item>
          )}
        /> */}
        {
          errorData.map((item, index) => <Alert
            key={index}
            message={item.fieldName == "newPassword" ? "新密码" : item.fieldName == "confirmPassword" ? "确认密码" : item.fieldName == "oldPassword" ? "旧密码" : ""}
            description={item.message}
            type="error"
            showIcon
            style={{ marginBottom: 10 }}
          />)
        }
      </Modal>
    </>
  )
}