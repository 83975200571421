import {dynamicWrapper, createRoute} from '@/utils/core';
import Routers from '@/layouts/Routers';
import NotFound from '@/routes/Pages/404';
import Property from './routers/index';

const routesConfig = (app) => ({
  path: '/taskDispatch',
  title: '任务调度管理',
  component: Routers,
  exact: false,
  indexRoute: '/taskDispatch/index',
  childRoutes: [
    Property(app),
    NotFound(),
  ]
});

export default (app) => createRoute(app, routesConfig);
