import { createRoutes } from '@/utils/core';
import BasicLayout from '@/layouts/BasicLayout';
import UserLayout from '@/layouts/UserLayout';
import NotFound from './Pages/404';
import Login from './Login';
import Register from './Register';
import Home from './Home';
import Factory from './Factory';
import Model from './Model';
import System from './System';
import Tpl from './Tpl';
import DataAnalysis from './DataAnalysis';
import DigitalScreen from './DigitalScreen';
import Fill from './Sheet/Fill';
import Forms from './Sheet/Forms';
import PrivateFill from './Sheet/PrivateFill';
import Algorithm from './Algorithm';
import Property from './Property';
import DataQuality from './DataQuality';
import TaskDispatch from './TaskDispatch';
import Collect from './Collect';
import IndustryModel from './IndustryModel'
import Warning from './Warning';
import Log from './Log';
import Help from './Help';

/**
 * 主路由配置
 * 
 * path 路由地址
 * component 组件
 * indexRoute 默认显示路由
 * childRoutes 所有子路由
 * NotFound 路由要放到最下面，当所有路由当没匹配到时会进入这个页面
 */

const routesConfig = app => [
	{
		path: '/sign',
		title: '登录',
		indexRoute: '/sign/login',
		component: UserLayout,
		childRoutes: [
			Login(app),
			Register(app),
		]
	},
	{
		path: '/help',
		title: '帮助中心',
		component: UserLayout,
		childRoutes: [
			Help(app)
		]
	},
	{
		path: '/',
		title: '系统中心',
		component: BasicLayout,
		indexRoute: '/home',
		childRoutes: [
			Login(app),
			Register(app),
			Home(app),
			Factory(app),
			Model(app),
			System(app),
			Tpl(app),
			DataAnalysis(app),
			DigitalScreen(app),
			Fill(app),
			Forms(app),
      PrivateFill(app),
			Algorithm(app),
			Property(app),
			DataQuality(app),
			TaskDispatch(app),
			Collect(app),
			IndustryModel(app),
			Warning(app),
      Log(app),
			NotFound()
		]
	}
];

export default app => createRoutes(app, routesConfig);