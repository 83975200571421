import {dynamicWrapper, createRoute} from '@/utils/core';
import Routers from '@/layouts/Routers';
import NotFound from '@/routes/Pages/404';
import WarningMessage from './routers/WarningMessage';
import WarningRules from './routers/WarningRules';
import WarningEvents from './routers/WarningEvents';

const routesConfig = (app) => ({
  path: '/warning',
  title: '告警管理',
  component: Routers,
  exact: false,
  indexRoute: '/warning/warningRules',
  childRoutes: [
    WarningMessage(app),
    WarningRules(app),
    WarningEvents(app),
    NotFound(),
  ]
});

export default (app) => createRoute(app, routesConfig);
