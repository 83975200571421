import {dynamicWrapper, createRoute} from '@/utils/core';
import Routers from '@/layouts/Routers';
import NotFound from '@/routes/Pages/404';
import CollectDevice from './routers/CollectDevice';
import CollectChannel from './routers/CollectChannel';

const routesConfig = (app) => ({
  path: '/collect',
  title: '采集管理',
  component: Routers,
  exact: false,
  indexRoute: '/collect/collectDevice',
  childRoutes: [
    CollectDevice(app),
    CollectChannel(app),
    NotFound(),
  ]
});

export default (app) => createRoute(app, routesConfig);
