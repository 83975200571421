import {createRoute} from '@/utils/core';
import Routers from '@/layouts/Routers';
import NotFound from '@/routes/Pages/404';
import PointQuality from './routers/PointQuality';
import QualityMonitoring from './routers/QualityMonitoring';
import PerformanceAppraisal from './routers/PerformanceAppraisal';
import DataAnalysisApply from './routers/DataAnalysisApply';
import SPLAnalyse from './routers/SPLAnalyse';

const routesConfig = (app) => ({
  path: '/dataQuality',
  title: '数据质量',
  component: Routers,
  exact: false,
  indexRoute: '/dataQuality/qualityRuleManage',
  childRoutes: [
    PointQuality(app),
    QualityMonitoring(app),
    PerformanceAppraisal(app),
    DataAnalysisApply(app),
    SPLAnalyse(app),
    NotFound(),
  ]
});

export default (app) => createRoute(app, routesConfig);
